import axios from 'axios';
import { BASE_URL } from './constants';

export const countriesEndpoint = axios.get(`${BASE_URL}/countries`);

export const getFiatCurrencyEndpoint = (country) => {
  return axios.get(`${BASE_URL}/fiat-currency?country=${country}`);
};

export const getCryptoCurrencyEndpoint = () => {
  return axios.get(`${BASE_URL}/crypto-currency`);
};

export const getCryptoPriceEndpoint = (fiat, crypto) => {
  return axios.get(`${BASE_URL}/crypto-list?fiat=${fiat}&crypto=${crypto}`);
};

export const getFiatPriceEndpoint = (crypto, fiat) => {
  return axios.get(`${BASE_URL}/fiat-list?crypto=${crypto}&fiat=${fiat}`);
};

export const getP2PPriceEndpoint = (fiat, crypto) => {
  return axios.get(
    `${BASE_URL}/p2p-price?fiat=${fiat}&crypto=${crypto}`,
  );
};

export const getP2PPricePaginationEndpoint = (fiat, crypto, exchange, page) => {
  return axios.get(
    `${BASE_URL}/p2p-pagination?fiat=${fiat}&crypto=${crypto}&exchange=${exchange}&page=${page}`,
  );
};

export const getGeneralTranslationsEndpoint = () => {
  return axios.get(`${BASE_URL}/page/general-translations`);
}

export const getP2PPageTranslationsEndpoint = () => {
  return axios.get(`${BASE_URL}/page/p2p`);
}

export const getContactsPageTranslationsEndpoint = () => {
  return axios.get(`${BASE_URL}/page/contacts`);
}

export const getExpressPricePageTranslationsEndpoint = () => {
  return axios.get(`${BASE_URL}/page/express-price`);
}

export const getPartnersPageTranslationsEndpoint = () => {
  return axios.get(`${BASE_URL}/page/partners`);
}

export const getFAQPageTranslationsEndpoint = () => {
  return axios.get(`${BASE_URL}/page/faq`);
}

export const getBlogPostsTranslationsEndpoint = () => {
  return axios.get(`${BASE_URL}/posts`);
}

export const getBlogPostTranslationsEndpoint = (slug) => {
  return axios.get(`${BASE_URL}/posts/${slug}`);
}
export const getCommentsEndpoint = (exchange) => {
  return axios.get(`${BASE_URL}/comments?exchange=${exchange}`);
}

export const postCommentEndpoint = (exchange, email, name, comment, rating) => {
  const formData = new FormData();
  formData.append('exchange', exchange);
  formData.append('email', email);
  formData.append('name', name);
  formData.append('comment', comment);
  formData.append('rating', rating);

  return axios.post(`${BASE_URL}/comments/create`, formData);
}

export const getNewsEndpoint = (language) => {
  return axios.get(`${BASE_URL}/posts?language=${language}`);
};

export const getSingleNewsEndpoint = (postId, language) => {
  return axios.get(`${BASE_URL}/posts/${postId}?language=${language}`)
}