import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import styles from './styles.module.scss';

import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as HeaderLogo } from '../../assets/HeaderLogo.svg';
import { ReactComponent as LeftHeaderStar } from '../../assets/leftHeaderStar.svg';
import { ReactComponent as RightHeaderStar } from '../../assets/rightHeaderStar.svg';
import { ReactComponent as Star1 } from '../../assets/MobileHeader/star-1.svg';
import { ReactComponent as Star2 } from '../../assets/MobileHeader/star-2.svg';
import { ReactComponent as Star3 } from '../../assets/MobileHeader/star-3.svg';
import faqImage from '../../assets/Header/faqRight.png';
import contactsImage from '../../assets/Header/contactsRight.png';
import Dropdown from '../../components/Dropdown/Dropdown';
import PageHeader from '../PageHeader/PageHeader';
import HeaderIcons from '../HeaderIcons/HeaderIcons';
import Container from '../../components/Container/Container';
import { useMediaQuery } from '../../../hooks/hooks';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import PageMobileHeader from '../PageMobileHeader/PageMobileHeader';
import { countries, countryCode, countryList, langList, languageOptions } from '../../../helpers/helpers';
import i18n from '../../../i18n';
import { fetchGeneralTranslations } from '../../../store/getTranslationsSlice';

const Header = () => {
  const dispatch = useDispatch();
  const countriesSelector = useSelector((state) => state.changeCountries.countries);
  const location = useLocation();
  const navigate = useNavigate();
  const burgerVisible = useMediaQuery('(max-width: 600px)');
  const showLogo = useMediaQuery('(min-width: 600px) and (max-width: 900px)');

  let language = langList.includes(location.pathname.split('/')[1]) ? location.pathname.split('/')[1] : 'en'
  let country = countries.includes(location.pathname.split('/')[1]) ? location.pathname.split('/')[1] : (countries.includes(location.pathname.split('/')[2]) ? location.pathname.split('/')[2] : 'brazil')


  let addPathExt = language == 'en' && country == 'brazil' ? '/' : (language == 'en' && country != 'brazil' ? `/${country}/` : `/${language}/${country}/`)
  let addPath = language == 'en' ? '/' : `/${language}/`

  const translations = useSelector(
    (state) => state.translations?.general?.data?.data[language.toUpperCase()],
  );

  const translationsHeader = useSelector(
    (state) =>
      state.translations?.expressPricePage?.data?.data[language.toUpperCase()].title,
  );

  let headerTitle = translationsHeader;

  if(location.pathname.includes('partners')) headerTitle = translations?.header_partners
  if(location.pathname.includes('contacts')) headerTitle = translations?.header_contacts
  if(location.pathname.includes('faq')) headerTitle = translations?.header_faq
  if(location.pathname.includes('news')) headerTitle = translations?.header_news

  const pathSegment =
    language === 'en'
      ? location.pathname.split('/')[1]
      : location.pathname.split('/')[2];
  const shouldShowHeaderIcons =
    location.pathname !== '/blog' &&
    location.pathname !== '/calculator' &&
    location.pathname !== '/terms-condition-privacy' &&
    location.pathname !== '/coins' &&
    !location.pathname.includes('view-news');

  useEffect(() => {
    !translations && dispatch(fetchGeneralTranslations());
  }, [dispatch, translations]);

  return (
    <>
      {!burgerVisible ? (
        <div
          className={cn(styles.headerWrapper, {
            [styles.forSingleNews]: location.pathname.includes('view-news'),
          })}
        >
          <Container>
            {showLogo && (
              <div className={styles.logoForTablet}>
                <HeaderLogo
                  height={29}
                  width={195}
                  className={styles.logo}
                  onClick={() =>
                    navigate(`${addPathExt}/`)
                  }
                />
              </div>
            )}
            <div className={styles.topSection}>
            {headerTitle !== translationsHeader && (
                <RightHeaderStar
                  height={20}
                  width={20}
                  className={cn(styles.leftStar, {
                    [styles.default]: `${addPathExt}/p2p`,
                    [styles.faq]: `${addPath}/faq`
                  })}
                />
              )}
              <div className={styles.navigateWrapper}>
                <div
                  className={cn(styles.navigate, {
                    [styles.forSingleNews]:
                      location.pathname.includes('view-news'),
                  })}
                >
                  {!showLogo && (
                    <HeaderLogo
                      height={29}
                      width={195}
                      className={styles.logo}
                      onClick={() => navigate(`${addPathExt}`)}
                    />
                  )}
                  <div className={styles.navLinksWrapper}>
                    <div className={styles.navLinks}>
                      <NavLink
                        to={
                          `${addPathExt}`
                        }
                        className={({ isActive }) =>
                          isActive ||
                          location.pathname === '/' ||
                          location.pathname === `/${language}`
                            ? styles.activeLink
                            : styles.link
                        }
                      >
                        {translations?.header_monitoring}
                      </NavLink>
                      <NavLink
                        to={
                          `${addPathExt}p2p`
                        }
                        className={({ isActive }) =>
                          isActive ? styles.activeLink : styles.link
                        }
                      >
                        {translations?.header_p2p}
                      </NavLink>

                      <NavLink
                        to={
                          `${addPath}partners`
                        }
                        className={({ isActive }) =>
                          isActive ? styles.activeLink : styles.link
                        }
                      >
                        {translations?.header_partners}
                      </NavLink>
                      <NavLink
                        to={`${addPath}faq`}
                        className={({ isActive }) =>
                          isActive ? styles.activeLink : styles.link
                        }
                      >
                        {translations?.header_faq}
                      </NavLink>
                      <NavLink
                        to={
                          `${addPath}contacts`
                        }
                        className={({ isActive }) =>
                          isActive ? styles.activeLink : styles.link
                        }
                      >
                        {translations?.header_contacts}
                      </NavLink>
                      <NavLink
                        to={`${addPath}news`}
                        className={({ isActive }) =>
                          isActive ? styles.activeLink : styles.link
                        }
                      >
                        {translations?.header_news}
                      </NavLink>
                    </div>
                    <div className={styles.navLine} />
                  </div>
                  <div className={styles.dropdownSection}>
                    <Dropdown
                      options={languageOptions}
                      languageDropdown
                      currentLanguage={i18n.resolvedLanguage}
                      pathSegment={pathSegment}
                    />

                    <Dropdown
                      options={countriesSelector?.data}
                      countryDropdown
                      pathSegment={pathSegment}
                    />
                  </div>
                </div>
              </div>
              { headerTitle !== translationsHeader &&(
                <RightHeaderStar
                  height={20}
                  width={20}
                  className={cn(styles.rightStar, {
                    [styles.default]: `${addPathExt}/p2p`,
                    [styles.faq]: `${addPath}/faq`
                  })}
                />
              )}
            </div>
            <div className={styles.headerIconsContainer}>
              {shouldShowHeaderIcons && <HeaderIcons />}
            </div>
            {!location.pathname.includes('view-news') && (
              <PageHeader title={headerTitle} />
            )}
          </Container>
        </div>
      ) : (
        <div className={styles.mobileContainer}>
          <div
            className={cn(styles.mobileWrapper, {
              [styles.forSingleNews]: location.pathname.includes('view-news'),
            })}
          >
            <div className={styles.topSection}>
              <HeaderLogo
                height={29}
                width={161}
                className={styles.logo}
                onClick={() =>
                  navigate(`${addPathExt}`)
                }
              />
              <div id="page-wrap">
                <BurgerMenu pageWrapId={'page-wrap'} />
              </div>
            </div>
          </div>
          {!location.pathname.includes('view-news') && (
            <>
              <div className={styles.divider} />
              <div className={styles.header}>
                <PageMobileHeader title={translationsHeader} />
                {(location.pathname === `/faq` ||
                  location.pathname === `${addPath}/faq`) && (
                  <img
                    alt="faq"
                    src={faqImage}
                    className={styles.image}
                    width={68}
                    height={61}
                  />
                )}
                {(location.pathname === `/contacts` ||
                  location.pathname === `${addPath}/contacts`) && (
                  <img
                    alt="contacts"
                    src={contactsImage}
                    className={styles.image}
                    width={68}
                    height={61}
                  />
                )}

                <Star1
                  className={cn(styles.star1, {
                    [styles.default]:
                      language === 'en'
                        ? location.pathname === `/${countryCode}`
                        : location.pathname === `/${language}/${countryCode}`,
                    [styles.faqPage]: location.pathname.includes('faq'),
                    [styles.othersPage]:
                      location.pathname !== `/${language}/faq` &&
                      location.pathname !== `/faq` &&
                      location.pathname !== `/${language}/${countryCode}` &&
                      location.pathname !== `/${countryCode}`,
                  })}
                />
                <Star2
                  className={cn(styles.star2, {
                    [styles.default]:
                      language === 'en'
                        ? location.pathname === `/${countryCode}`
                        : location.pathname === `/${language}/${countryCode}`,
                    [styles.faqPage]: location.pathname.includes('faq'),
                    [styles.othersPage]:
                      location.pathname !== `/${language}/faq` &&
                      location.pathname !== `/faq` &&
                      location.pathname !== `/${language}/${countryCode}` &&
                      location.pathname !== `/${countryCode}`,
                  })}
                />
                <Star3
                  className={cn(styles.star3, {
                    [styles.default]:
                      language === 'en'
                        ? location.pathname === `/${countryCode}`
                        : location.pathname === `/${language}/${countryCode}`,
                    [styles.faqPage]: location.pathname.includes('faq'),
                    [styles.contactsPage]:
                      location.pathname === `/${language}/contacts` ||
                      location.pathname === `/contacts`,
                    [styles.othersPage]:
                      location.pathname !== `/${language}/faq` &&
                      location.pathname !== `/faq` &&
                      location.pathname !== `/${language}/${countryCode}` &&
                      location.pathname !== `/${countryCode}`,
                  })}
                  width={11}
                  height={11}
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Header;
