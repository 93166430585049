import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en/index';
import es from './es';
import pt from './pt';

export const fallbackLanguage = 'en';
export const availableLanguages = [fallbackLanguage, 'es', 'pt'];

const resources = {
  en: { translation: en },
  es: { translation: es },
  pt: { translation: pt },
};

i18n.use(initReactI18next).init({
  resources,
  lng: window.localStorage.getItem('userLanguage'),
  fallbackLng: fallbackLanguage,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export function changeLanguage(language, callback) {
  window.localStorage.setItem('userLanguage', language);
  i18n.changeLanguage(language, () => {
    if (callback) {
      callback();
    }
  });
}

export function getPathnameForNewLanguage(language) {
  const params = window.location.search;
  const hash = window.location.hash;
  const pathnameArr = window.location.pathname.replaceAll('//', '/').split('/');

  if (!pathnameArr[0]) {
    pathnameArr.shift();
  }

  const isFirstPartLocale =
    pathnameArr[0] === fallbackLanguage || availableLanguages.includes(pathnameArr[0]);

  if (isFirstPartLocale) {
    pathnameArr.shift();
  }

  if (language !== fallbackLanguage) {
    pathnameArr.unshift(language);
  }

  return '/' + pathnameArr.join('/') + hash + params;
}

export default i18n;
