import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getGeneralTranslationsEndpoint,
  getP2PPageTranslationsEndpoint,
  getContactsPageTranslationsEndpoint,
  getExpressPricePageTranslationsEndpoint,
  getPartnersPageTranslationsEndpoint,
  getFAQPageTranslationsEndpoint,
  getBlogPostsTranslationsEndpoint,
  getBlogPostTranslationsEndpoint,
} from '../api/endpoints';

export const fetchGeneralTranslations = createAsyncThunk(
  'generalTranslations/fetchGeneralTranslations',
  async () => {
    try {
      const response = await getGeneralTranslationsEndpoint();
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchP2PPageTranslations = createAsyncThunk(
  'p2pPageTranslations/fetchP2PPageTranslations',
  async () => {
    try {
      const response = await getP2PPageTranslationsEndpoint();
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchContactsPageTranslations = createAsyncThunk(
  'contactsPageTranslations/fetchContactsPageTranslations',
  async () => {
    try {
      const response = await getContactsPageTranslationsEndpoint();
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchExpressPricePageTranslations = createAsyncThunk(
  'expressPricePageTranslations/fetchExpressPricePageTranslations',
  async () => {
    try {
      const response = await getExpressPricePageTranslationsEndpoint();
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchPartnersPageTranslations = createAsyncThunk(
  'partnersPageTranslations/fetchPartnersPageTranslations',
  async () => {
    try {
      const response = await getPartnersPageTranslationsEndpoint();
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchFAQPageTranslations = createAsyncThunk(
  'faqPageTranslations/fetchFAQPageTranslations',
  async () => {
    try {
      const response = await getFAQPageTranslationsEndpoint();
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchPostsTranslations = createAsyncThunk(
  'postsPageTranslations/fetchPostsPageTranslations',
  async () => {
    try {
      const response = await getBlogPostsTranslationsEndpoint();
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchPostTranslations = createAsyncThunk(
  'postPageTranslations/fetchPostPageTranslations',
  async () => {
    try {
      const response = await getBlogPostTranslationsEndpoint();
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

const translationsSlice = createSlice({
  name: 'translations',
  initialState: {
    general: {
      data: null,
      loading: false,
      error: null,
    },
    p2pPage: {
      data: null,
      loading: false,
      error: null,
    },
    contactsPage: {
      data: null,
      loading: false,
      error: null,
    },
    expressPricePage: {
      data: null,
      loading: false,
      error: null,
    },
    partnersPage: {
      data: null,
      loading: false,
      error: null,
    },
    faqPage: {
      data: null,
      loading: false,
      error: null,
    },
    blogPosts: {
      data: null,
      loading: false,
      error: null,
    },
    blogPost: {
      data: null,
      loading: false,
      error: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGeneralTranslations.pending, (state) => {
        state.general.loading = true;
        state.general.error = null;
      })
      .addCase(fetchGeneralTranslations.fulfilled, (state, action) => {
        state.general.loading = false;
        state.general.data = action.payload;
      })
      .addCase(fetchGeneralTranslations.rejected, (state, action) => {
        state.general.loading = false;
        state.general.error = action.error.message;
      })
      .addCase(fetchP2PPageTranslations.pending, (state) => {
        state.p2pPage.loading = true;
        state.p2pPage.error = null;
      })
      .addCase(fetchP2PPageTranslations.fulfilled, (state, action) => {
        state.p2pPage.loading = false;
        state.p2pPage.data = action.payload;
      })
      .addCase(fetchP2PPageTranslations.rejected, (state, action) => {
        state.p2pPage.loading = false;
        state.p2pPage.error = action.error.message;
      })
      .addCase(fetchContactsPageTranslations.pending, (state) => {
        state.contactsPage.loading = true;
        state.contactsPage.error = null;
      })
      .addCase(fetchContactsPageTranslations.fulfilled, (state, action) => {
        state.contactsPage.loading = false;
        state.contactsPage.data = action.payload;
      })
      .addCase(fetchContactsPageTranslations.rejected, (state, action) => {
        state.contactsPage.loading = false;
        state.contactsPage.error = action.error.message;
      })
      .addCase(fetchExpressPricePageTranslations.pending, (state) => {
        state.expressPricePage.loading = true;
        state.expressPricePage.error = null;
      })
      .addCase(fetchExpressPricePageTranslations.fulfilled, (state, action) => {
        state.expressPricePage.loading = false;
        state.expressPricePage.data = action.payload;
      })
      .addCase(fetchExpressPricePageTranslations.rejected, (state, action) => {
        state.expressPricePage.loading = false;
        state.expressPricePage.error = action.error.message;
      })
      .addCase(fetchPartnersPageTranslations.pending, (state) => {
        state.partnersPage.loading = true;
        state.partnersPage.error = null;
      })
      .addCase(fetchPartnersPageTranslations.fulfilled, (state, action) => {
        state.partnersPage.loading = false;
        state.partnersPage.data = action.payload;
      })
      .addCase(fetchPartnersPageTranslations.rejected, (state, action) => {
        state.partnersPage.loading = false;
        state.partnersPage.error = action.error.message;
      })
      .addCase(fetchFAQPageTranslations.pending, (state) => {
        state.faqPage.loading = true;
        state.faqPage.error = null;
      })
      .addCase(fetchFAQPageTranslations.fulfilled, (state, action) => {
        state.faqPage.loading = false;
        state.faqPage.data = action.payload;
      })
      .addCase(fetchFAQPageTranslations.rejected, (state, action) => {
        state.faqPage.loading = false;
        state.faqPage.error = action.error.message;
      })
      .addCase(fetchPostsTranslations.pending, (state) => {
        state.blogPosts.loading = true;
        state.blogPosts.error = null;
      })
      .addCase(fetchPostsTranslations.fulfilled, (state, action) => {
        state.blogPosts.loading = false;
        state.blogPosts.data = action.payload;
      })
      .addCase(fetchPostsTranslations.rejected, (state, action) => {
        state.blogPosts.loading = false;
        state.blogPosts.error = action.error.message;
      })
      .addCase(fetchPostTranslations.pending, (state) => {
        state.blogPost.loading = true;
        state.blogPost.error = null;
      })
      .addCase(fetchPostTranslations.fulfilled, (state, action) => {
        state.blogPost.loading = false;
        state.blogPost.data = action.payload;
      })
      .addCase(fetchPostTranslations.rejected, (state, action) => {
        state.blogPost.loading = false;
        state.blogPost.error = action.error.message;
      });
  },
});

export default translationsSlice.reducer;
