import { createSlice } from '@reduxjs/toolkit';

const changeFiatCountSlice = createSlice({
  name: 'changeFiatCount',
  initialState: {
    count: parseInt(0),
  },
  reducers: {
    changeFiatCount: (state, newState) => {
      state.count = newState.payload;
    },
  },
});

export const { changeFiatCount } = changeFiatCountSlice.actions;
export default changeFiatCountSlice.reducer;