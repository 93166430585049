import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getSingleNewsEndpoint } from '../api/endpoints';

export const fetchSingleNews = createAsyncThunk(
  'singleNews/fetchSingleNews',
  async ({ postId, language }) => {
    try {
      const response = await getSingleNewsEndpoint(postId, language);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

const singleNewsSlice = createSlice({
  name: 'singleNews',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleNews.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSingleNews.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchSingleNews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default singleNewsSlice.reducer;
