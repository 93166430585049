import { createSlice } from '@reduxjs/toolkit';

const changeBestP2PBuySlice = createSlice({
  name: 'changeBestP2PBuy',
  initialState: {
    price: parseInt(1),
  },
  reducers: {
    changeBestP2PBuy: (state, newState) => {
      state.price = newState.payload;
    },
  },
});

export const { changeBestP2PBuy } = changeBestP2PBuySlice.actions;
export default changeBestP2PBuySlice.reducer;