import i18n from './i18n/index';
import { Routing } from './router/router';
import MainLayout from './shared/layout/MainLayout';
import React, { Suspense, useEffect, useState } from 'react';
import Spinner from './shared/components/Spinner/Spinner';
import { ScrollToTop } from './hooks/hooks';
import { fetchCountries } from './store/changeCountriesSlice';
import { useDispatch } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { useParams } from 'react-router-dom';

function App() {
  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    Promise.all([dispatch(fetchCountries())]).then(() => {
      setInitialLoading(false);
    });
  }, [dispatch, initialLoading]);

  return (
    <div className="App">
      <MainLayout>
        <Suspense fallback={initialLoading ? <Spinner /> : null}>
          <ScrollToTop />
          <I18nextProvider i18n={i18n}>
            <Routing />
          </I18nextProvider>
        </Suspense>
      </MainLayout>
    </div>
  );
}

export default App;
