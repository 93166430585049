import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { ReactComponent as FooterLogo } from '../../assets/footerLogo.svg';
import { ReactComponent as InstagramIcon } from '../../assets/instagramIcon.svg';
import { ReactComponent as TwitterIcon } from '../../assets/twitterIcon.svg';
import { ReactComponent as LinkedinIcon } from '../../assets/linkedinIcon.svg';
import { ReactComponent as FacebookIcon } from '../../assets/facebookIcon.svg';
import { ReactComponent as TrustPilotIcon } from '../../assets/trustpilotblack.svg';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import Container from '../../components/Container/Container';
import { countries, countryCode, langList } from '../../../helpers/helpers';
import { fetchGeneralTranslations } from '../../../store/getTranslationsSlice';

const Footer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation()

  let language = langList.includes(location.pathname.split('/')[1]) ? location.pathname.split('/')[1] : 'en'
  let country = countries.includes(location.pathname.split('/')[1]) ? location.pathname.split('/')[1] : (countries.includes(location.pathname.split('/')[2]) ? location.pathname.split('/')[2] : 'brazil')

  const translations = useSelector(
    (state) => state.translations.general.data?.data[language.toUpperCase()],
  );

  let addPathExt = language == 'en' ? '/' : (country == 'brazil' && language == 'en' ? `/${language}/` : `/${language}/${country}/`)
  let addPath = language == 'en' ? '/' : `/${language}/`

  useEffect(() => {
    !translations && dispatch(fetchGeneralTranslations());
  }, [dispatch, translations]);
  
  return (
    <Container>
      <div className={styles.footer}>
        <div className={styles.bottomSection}>
          <div className={styles.leftSection}>
            <FooterLogo
              height={29}
              width={195}
              onClick={() =>
                navigate(
                  language === 'en'
                    ? `/${countryCode}`
                    : `/${language}/${countryCode}`,
                )
              }
            />
          </div>
          <div className={styles.socialIcons}>
          <FacebookIcon></FacebookIcon>
          <TwitterIcon></TwitterIcon>
          <LinkedinIcon></LinkedinIcon>
          <InstagramIcon></InstagramIcon>
          <TrustPilotIcon width={94} height={23} />
          </div>
        </div>
        <div className={styles.topSection}>
          <div className={styles.footerNav}>
            <div className={styles.link}>
            <Link
              to={
                `${addPath}`
              }
            >
            {translations?.header_monitoring}
            </Link>
            </div>
            <div className={styles.link}>
            <Link
              to={
                `${addPathExt}p2p`
              }
            >
            {translations?.header_p2p}
            </Link>
            </div>
            <div className={styles.link}>
            <Link
              to={
                `${addPath}partners`
              }
            >
            {translations?.header_partners}
            </Link>
            </div>
            <div className={styles.link}>
            <Link
              to={`${addPath}faq`}
            >
            {translations?.header_faq}
            </Link>
            </div>
            <div className={styles.link}>
            <Link
              to={
                `${addPath}contacts`
              }
            >
            {translations?.header_contacts}
            </Link>
            </div>
            <div className={styles.link}>
            <Link
              to={`${addPath}news`}
            >
            {translations?.header_news}
            </Link>
            </div>
          </div>
        </div>
        <div className={styles.lastSection}>
            <div className={styles.grayLine}></div>
            <div className={styles.infos}>
              <div className={styles.footerInfo}>
                ©&nbsp;&nbsp;{new Date().getFullYear()}&nbsp; ChangeExperto
              </div>
              <div className={styles.links}>
                <Link>{translations?.footer_terms}</Link>
                <Link>{translations?.footer_privacy}</Link>
              </div>
            </div>
        </div>
      </div>
    </Container>
  );
};

export default Footer;