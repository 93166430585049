import { createSlice } from '@reduxjs/toolkit';

const changeSectionSlice = createSlice({
  name: 'changeSection',
  initialState: {
    reversed: true,
  },
  reducers: {
    toggleReversed: (state, newState) => {
      state.reversed = !state.reversed;
    },
  },
});

export const { toggleReversed } = changeSectionSlice.actions;
export default changeSectionSlice.reducer;
