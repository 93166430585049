import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from './styles.module.scss';
import ChangeSection from '../ChangeSection/ChangeSection';
import { countries, countryCode, langList } from '../../../helpers/helpers';

const PageHeader = ({title}) => {
  const location = useLocation();
  
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>{title}</div>
      {(location.pathname.includes('p2p')  && (
        <div className={styles.dropdownSection}>
          <ChangeSection monitoring={!location.pathname.includes('p2p')} />
        </div>
      ))}

      {(!location.pathname.includes('news') && !location.pathname.includes('contacts')&& !location.pathname.includes('partners')&& !location.pathname.includes('faq')) && !location.pathname.includes('p2p') && (        
      <div className={styles.dropdownSection}>
          <ChangeSection monitoring={!location.pathname.includes('p2p')} />
        </div>)}
    </div>
  );
};

export default PageHeader;
