import { createSlice } from '@reduxjs/toolkit';

const changeBestP2PSellSlice = createSlice({
  name: 'changeBestP2PSell',
  initialState: {
    price: parseInt(1),
  },
  reducers: {
    changeBestP2PSell: (state, newState) => {
      state.price = newState.payload;
    },
  },
});

export const { changeBestP2PSell } = changeBestP2PSellSlice.actions;
export default changeBestP2PSellSlice.reducer;