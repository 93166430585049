import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCryptoCurrencyEndpoint } from '../api/endpoints';

export const fetchCryptoCurrency = createAsyncThunk(
  'cryptoCurrency/fetchCryptoCurrency',
  async (crypto) => {
    try {
      const response = await getCryptoCurrencyEndpoint();
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const cryptoCurrencySlice = createSlice({
  name: 'cryptoCurrency',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCryptoCurrency.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCryptoCurrency.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchCryptoCurrency.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default cryptoCurrencySlice.reducer;
