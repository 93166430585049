import cn from 'classnames';

import styles from './styles.module.scss';
import PropTypes from 'prop-types';

const Container = ({ children, variant = 'md', fullHeight }) => (
  <div className={cn(styles.container, variant, { [styles.fullHeight]: fullHeight })}>
    {children}
  </div>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  fullHeight: PropTypes.bool,
};

export default Container;
