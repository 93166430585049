import component from './component.json';
import monitoring from './monitoring.json';
import partners from './partners.json';
import news from './news.json';
import faq from './faq.json';
import contacts from './contacts.json';

const defaultExport = { component, monitoring, partners, news, faq, contacts };

export default defaultExport;
