import { configureStore } from '@reduxjs/toolkit';
import changeSectionReducer from './changeSectionSlice';
import changeCountriesReducer from './changeCountriesSlice';
import fiatCurrencyReducer from './fiatCurrencySlice';
import cryptoCurrencyReducer from './cryptoCurrencySlice';
import cryptoPriceReducer from './getCryptoPriceSlice';
import fiatPriceReducer from './getFiatPriceSlice';
import p2pPriceReducer from './getP2PPriceSlice';
import p2pPricePaginationReducer from './getP2PPricePaginationSlice';
import translationsReducer from './getTranslationsSlice';
import commentsReducer from './getCommentsSlice';
import newsReducer from './getNewsSlice';
import singleNewsReducer from './getSingleNewsSlice';
import changeFiatReducer from './changeFiatCountSlice'
import changeCryptoReducer from './changeCryptoCountSlice'
import changeLastTypeReducer from './changeLastType';
import changeBestMonitoringPrice from './changeBestMonitoringPrice';
import changeBestP2PBuy from './changeBestP2PBuy';
import changeBestP2PSell from './changeBestP2PSell';
// import pricesReducer from './getPriceSlice';

const store = configureStore({
  reducer: {
    changeSection: changeSectionReducer,
    changeCountries: changeCountriesReducer,
    fiatCurrency: fiatCurrencyReducer,
    cryptoCurrency: cryptoCurrencyReducer,
    // prices: pricesReducer,
    cryptoPrice: cryptoPriceReducer,
    fiatPrice: fiatPriceReducer,
    p2pPrice: p2pPriceReducer,
    p2pPricePagination: p2pPricePaginationReducer,
    translations: translationsReducer,
    comments: commentsReducer,
    news: newsReducer,
    singleNews: singleNewsReducer,
    changeFiatCount: changeFiatReducer,
    changeCryptoCount: changeCryptoReducer,
    changeLastType: changeLastTypeReducer,
    changeBestMonitoringPrice: changeBestMonitoringPrice,
    changeBestP2PBuy: changeBestP2PBuy,
    changeBestP2PSell: changeBestP2PSell
  },
});

export default store;
