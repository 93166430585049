import { createSlice } from '@reduxjs/toolkit';

const changeCryptoCountSlice = createSlice({
  name: 'changeCryptoCount',
  initialState: {
    count: parseInt(0),
  },
  reducers: {
    changeCryptoCount: (state, newState) => {
      state.count = newState.payload;
    },
  },
});

export const { changeCryptoCount } = changeCryptoCountSlice.actions;
export default changeCryptoCountSlice.reducer;