import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getP2PPricePaginationEndpoint } from '../api/endpoints';

export const fetchP2PPricePagination = createAsyncThunk(
  'p2pPricePagination/fetchP2PPricePagination',
    async ({ fiat, crypto, exchange, page }) => {
    try {
      const response = await getP2PPricePaginationEndpoint(
        fiat,
        crypto,
        exchange,
        page,
        );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

const p2pPricePaginationSlice = createSlice({
  name: 'p2pPricePagination',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchP2PPricePagination.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchP2PPricePagination.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchP2PPricePagination.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default p2pPricePaginationSlice.reducer;
