import { lazy, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { countryCode } from '../helpers/helpers';
import Spinner from '../shared/components/Spinner/Spinner';
import RedirectPage from '../Pages/RedirectPage/RedirectPage';

const MonitoringPage = lazy(() =>
  import('../Pages/MonitoringPage/MonitoringPage'),
);
const P2PPage = lazy(() => import('../Pages/P2PPage/P2PPage'));
const PartnersPage = lazy(() => import('../Pages/PartnersPage/PartnersPage'));
const CoinsPage = lazy(() => import('../Pages/CoinsPage/CoinsPage'));
const FAQPage = lazy(() => import('../Pages/FAQPage/FAQPage'));
const ContactsPage = lazy(() => import('../Pages/ContactsPage/ContactsPage'));
const NewsPage = lazy(() => import('../Pages/NewsPage/NewsPage'));
const SingleNewsPage = lazy(() =>
  import('../Pages/NewsPage/SingleNewsPage/SingleNewsPage'),
);

const BlogPage = lazy(() => import('../Pages/BlogPage/BlogPage'));
const CalculatorPage = lazy(() =>
  import('../Pages/CalculatorPage/CalculatorPage'),
);
const TermsConditionsPage = lazy(() =>
  import('../Pages/TermsConditionsPage/TermsConditionsPage'),
);

export const Routing = () => {

  let location = useLocation()
  let [isLoading, setLoading] = useState(true)

  setTimeout(() => {
    setLoading(false)
  }, 2000)

  useEffect(() => {
    setLoading(true)
    
  setTimeout(() => {
    setLoading(false)
  }, 2000)
  }, [location.pathname])

  return (
    <Routes>
      <Route
        path={
          '/:lang?/:country?'
        }
        element={isLoading ? <Spinner/> : <MonitoringPage />}
      />
      <Route
        path={
          '/:lang?/:country?/p2p'
        }
        element={isLoading ? <Spinner /> : <P2PPage />}
      />
      <Route
        path={`/:lang?/partners`}
        element={isLoading ? <Spinner /> : <PartnersPage />}
      />
      <Route
        path={`/:lang?/faq`}
        element={isLoading ? <Spinner /> : <FAQPage />}
      />
      <Route
        path={`/:lang?/contacts`}
        index
        element={isLoading ? <Spinner /> : <ContactsPage />}
      />
      <Route
        path={`/:lang?/news`}
        element={isLoading ? <Spinner /> : <NewsPage />}
      />
      <Route
        path={
          `/:lang?/view-news/:id`
        }
        element={isLoading ? <Spinner /> : <SingleNewsPage />}
      />
      <Route path="blog" index element={isLoading ? <Spinner /> : <BlogPage />} />
      <Route path="calculator" index element={isLoading ? <Spinner /> : <CalculatorPage />} />
      <Route
        path="terms-condition-privacy"
        index
        element={<TermsConditionsPage />}
      />
      <Route path="*" element={<RedirectPage />} />
    </Routes>
  );
};
