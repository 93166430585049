import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { slide as Menu } from 'react-burger-menu';

import { NavLink, useLocation } from 'react-router-dom';
import styles from './styles.module.scss';
import './index.scss';
import Dropdown from '../../components/Dropdown/Dropdown';
import { countryCode, langList, languageOptions } from '../../../helpers/helpers';
import { countries } from '../../../helpers/helpers';
import i18n from '../../../i18n';
import { fetchGeneralTranslations } from '../../../store/getTranslationsSlice';

const BurgerMenu = ({ props }) => {
  const location = useLocation();
  let language = langList.includes(location.pathname.split('/')[1]) ? location.pathname.split('/')[1] : 'en'
  const dispatch = useDispatch();
  const countriesData = useSelector((state) => state.changeCountries.countries);
  const translations = useSelector(
    (state) => state.translations.general.data?.data[language.toUpperCase()],
  );

  let country = countries.includes(location.pathname.split('/')[1]) ? location.pathname.split('/')[1] : (countries.includes(location.pathname.split('/')[2]) ? location.pathname.split('/')[2] : 'brazil')


  let addPathExt = language == 'en' && country == 'brazil' ? '/' : (language == 'en' && country != 'brazil' ? `/${country}/` : `/${language}/${country}/`)
  let addPath = language == 'en' ? '/' : `/${language}/`

  const [isOpen, setOpen] = useState(false);
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }

    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [isOpen]);

  useEffect(() => {
    !translations && dispatch(fetchGeneralTranslations());
  }, [dispatch, translations]);

  const handleIsOpen = () => {
    setOpen(!isOpen);
  };

  const closeSideBar = () => {
    setOpen(false);
  };
  return (
    <Menu
      {...props}
      isOpen={isOpen}
      onOpen={handleIsOpen}
      onClose={handleIsOpen}
      right
      width={'100%'}
    >
      <NavLink
        to={
          `${addPathExt}`
        }
        className={styles.link}
        onClick={closeSideBar}
      >
        {translations?.header_monitoring}
      </NavLink>
      <NavLink
        to={
          `${addPathExt}p2p`
        }
        className={styles.link}
        onClick={closeSideBar}
      >
        {translations?.header_p2p}
      </NavLink>
      <NavLink
        to={
          `${addPath}partners`
        }
        className={styles.link}
        onClick={closeSideBar}
      >
        {translations?.header_partners}
      </NavLink>
      <NavLink
        to={`${addPath}faq`}
        className={styles.link}
        onClick={closeSideBar}
      >
        {translations?.header_faq}
      </NavLink>
      <NavLink
        to={
          `${addPath}contacts`
        }
        className={styles.link}
        onClick={closeSideBar}
      >
        {translations?.header_contacts}
      </NavLink>
      <NavLink
        to={`${addPath}news`}
        className={styles.link}
        onClick={closeSideBar}
      >
        {translations?.header_news}
      </NavLink>
      <div className={styles.dropdownSection}>
        <Dropdown
          options={languageOptions}
          languageDropdown
          currentLanguage={i18n.resolvedLanguage}
        />
        <Dropdown
          options={countriesData?.data}
          countryDropdown
        />
      </div>
    </Menu>
  );
};

export default BurgerMenu;
