import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { countryCode, langList } from '../../helpers/helpers';

const RedirectPage = () => {
  const navigate = useNavigate();
  let location = useLocation()
  const language = location.pathname.split('/')[1] == '' || !langList.includes(location.pathname.split('/')[1])  ? 'en' : location.pathname.split('/')[1];
  if (countryCode && language) {
    setTimeout(() => {
      const redirectPath = language === 'en' ? `/` : `/${language}`;
      navigate(redirectPath);
      return <Navigate to={redirectPath} />;
    }, 100);
  }
};

export default RedirectPage;
