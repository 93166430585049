import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './styles.module.scss';
import { changeFiatCount } from '../../../store/changeFiatCountSlice';
import { changeCryptoCount } from '../../../store/changeCryptoCountSlice';
import {changeLastType} from '../../../store/changeLastType';
import { useDispatch, useSelector } from 'react-redux';

const regex = /^[0-9.]+$/;

const Input = ({
  title,
  placeholder,
  type,
  error,
  withIcon,
  icon,
  monitoring,
  fiat,
  dropdownInput,
  code
}) => {

  const dispatch = useDispatch();
  
  function handleInput(e) {
    let val = 0

    if(e.target.value.slice(-1) == '.') {
      val = e.target.value
      
      if(fiat) {
        dispatch(changeFiatCount(val))
        dispatch(changeLastType('fiat'))
      } else {
        dispatch(changeCryptoCount(val))
        dispatch(changeLastType('crypto'))
      }

      return
    } 

    if(e.target.value != '') {
      val = parseFloat(e.target.value)
    }

    if(fiat) {
      dispatch(changeFiatCount(val))
      dispatch(changeLastType('fiat'))
    } else {
      dispatch(changeCryptoCount(val))
      dispatch(changeLastType('crypto'))
    }
  }

  const reversedIcon = useSelector((state) => state.changeSection.reversed);
  let bestPriceMonitoring = useSelector((state) => state.changeBestMonitoringPrice.price)
  let bestSell = useSelector((state) => state.changeBestP2PSell.price)
  let bestBuy = useSelector((state) => state.changeBestP2PBuy.price)

  let lastType = useSelector((state) => state.changeLastType.type)

  let fiatCount = useSelector((state) => state.changeFiatCount.count)
  
  let cryptoCount = useSelector((state) => state.changeCryptoCount.count)

  let [valueField, setValue] = useState(1);

  useEffect(() => {
    if(monitoring) {
      if(lastType == 'fiat') {
        if(type == 'fiat') {
          setValue(fiatCount)
        } else {
          if(reversedIcon) {
            setValue((fiatCount / bestPriceMonitoring).toFixed(code == 'ETH' || code == 'BTC' ? 7 : (code == 'BRL' ? 3 : 2)))
          } else {
            setValue((fiatCount * bestPriceMonitoring).toFixed(code == 'ETH' || code == 'BTC' ? 7 : (code == 'BRL' ? 3 : 2)))
          }
        }
      } else if(lastType == 'crypto') {
        if(type == 'fiat') {
          if(reversedIcon) {
            setValue((cryptoCount * bestPriceMonitoring).toFixed(code == 'ETH' || code == 'BTC' ? 7 : (code == 'BRL' ? 3 : 2)))
          } else {
            setValue((cryptoCount / bestPriceMonitoring).toFixed(code == 'ETH' || code == 'BTC' ? 7 : (code == 'BRL' ? 3 : 2)))
          }
        } else {
          setValue(cryptoCount)
        }
      }
    } else {
      if(lastType == 'crypto') {
        if(type == 'crypto') {
          setValue(cryptoCount)
        } else {
          setValue((cryptoCount * bestSell).toFixed(code == 'ETH' || code == 'BTC' ? 7 : (code == 'BRL' ? 3 : 2)))
        }
      } else if(lastType == 'fiat') {
        if(type == 'fiat') {
          setValue(fiatCount)
        } else {
          setValue((fiatCount / bestBuy).toFixed(code == 'ETH' || code == 'BTC' ? 7 : (code == 'BRL' ? 3 : 2)))
        }
      }
    }
  }, [lastType, fiatCount, cryptoCount, bestPriceMonitoring, monitoring, bestSell, bestBuy])

  return (
    <div className={styles.inputWrapper}>
      <div className={styles.title}>{title}</div>
      {withIcon && (
        <div className={styles.iconLabel}>
          {icon ? <img src={icon} alt="input img" /> : null}
        </div>
      )}
        <input
          className={cn(styles.input, {
            [styles.error]: error,
            [styles.withIcon]: withIcon,
            [styles.monitoring]: false,
            [styles.dropdownInput]: dropdownInput,
          })}
          placeholder={placeholder}
          value={valueField}
          onChange={(e) => {handleInput(e)}}
        />
      {error && <div className={styles.errorMessage}>{error}</div>}
    </div>
  );
};

Input.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string || PropTypes.number,
  title: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string,
  withIcon: PropTypes.bool,
  textarea: PropTypes.bool,
  monitoring: PropTypes.bool,
};

export default Input;
