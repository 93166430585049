import { createSlice } from '@reduxjs/toolkit';

const changeLastTypeSlice = createSlice({
  name: 'changeLastType',
  initialState: {
    type: 'crypto',
  },
  reducers: {
    changeLastType: (state, newState) => {
      state.type = newState.payload;
    },
  },
});

export const { changeLastType } = changeLastTypeSlice.actions;
export default changeLastTypeSlice.reducer;