import styles from './styles.module.scss';
import cn from 'classnames';

const Spinner = ({ contentHeight }) => {
  return (
    <div
      className={cn(styles.spinnerContainer, {
        [styles.contentHeight]: contentHeight,
      })}
    >
      <div className={styles.loadingSpinner}></div>
    </div>
  );
};

export default Spinner;
