import { createSlice } from '@reduxjs/toolkit';

const changeBestMonitoringPriceSlice = createSlice({
  name: 'changeBestMonitoringPrice',
  initialState: {
    price: parseInt(1),
  },
  reducers: {
    changeBestMonitoringPrice: (state, newState) => {
      state.price = newState.payload;
    },
  },
});

export const { changeBestMonitoringPrice } = changeBestMonitoringPriceSlice.actions;
export default changeBestMonitoringPriceSlice.reducer;