import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import creditCard from '../../assets/crypto/creditCardIcon.svg';
import Dropdown from '../../components/Dropdown/Dropdown';
import InputDropdown from '../../components/InputDropdown/InputDropdown';
import { ReactComponent as ChangeIcon } from '../../assets/changeIcon.svg';
import styles from './styles.module.scss';
import { toggleReversed } from '../../../store/changeSectionSlice';
import { useMediaQuery } from '../../../hooks/hooks';
import { fetchFiatCurrency } from '../../../store/fiatCurrencySlice';
import { fetchCryptoPrice } from '../../../store/getCryptoPriceSlice';
import { fetchFiatPrice } from '../../../store/getFiatPriceSlice';
import { fetchCryptoCurrency } from '../../../store/cryptoCurrencySlice';
import { fetchP2PPrice } from '../../../store/getP2PPriceSlice';
import {
  getInitialFiatByCountry,
  getInitialCountryByFiat,
  countryList,
  langList,
  countries,
} from '../../../helpers/helpers';
import {changeLastType} from '../../../store/changeLastType';
import { changeCryptoCount } from '../../../store/changeCryptoCountSlice';
import {changeFiatCount} from '../../../store/changeFiatCountSlice'

const ChangeSection = ({ monitoring }) => {
  const location = useLocation();
  let selectedCountry = countries.includes(location.pathname.split('/')[1]) ? location.pathname.split('/')[1] : (countries.includes(location.pathname.split('/')[2]) ? location.pathname.split('/')[2] : 'brazil')


  const storedCrypto = JSON.parse(localStorage?.getItem('selectedCrypto')) || {
    name: 'Bitcoin',
    code: 'BTC',
    icon: 'https://api-changeexperto.goodface.com.ua/storage/currencies/crypto/btc.svg',
  };
  const storedP2PCrypto = JSON.parse(
    localStorage?.getItem('selectedP2PCrypto'),
  ) || {
    name: 'Bitcoin',
    code: 'BTC',
    icon: 'https://api-changeexperto.goodface.com.ua/storage/currencies/crypto/btc.svg',
  };

  const [p2pCryptoAmount, setP2PCryptoAmount] = useState('');
  const [selectedCrypto, setSelectedCrypto] = useState(storedCrypto);
  const [selectedP2PCrypto, setSelectedP2PCrypto] = useState(storedP2PCrypto);
  const [selectedFiat, setSelectedFiat] = useState(
    getInitialFiatByCountry(selectedCountry),
  );
  const isP2PPage = location.pathname.includes('p2p');

  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width: 800px)');
  const reversedIcon = useSelector((state) => state.changeSection.reversed);
  const cryptoList = useSelector((state) => state.cryptoCurrency?.data);
  const cryptoSellPrice = useSelector((state) => {
    const p2pData = state.p2pPrice?.data?.data[0]?.data;

    if (p2pData && Array.isArray(p2pData.sell) && p2pData.sell[0]?.price) {
      return p2pData.sell[0].price;
    } else {
      return null;
    }
  });
  const p2pFiatAmount = (p2pCryptoAmount * cryptoSellPrice).toString();

  // const [reversed, setReversed] = useState(
  //   localStorage.getItem('reversed') === 'true' ? true : false,
  // );

  const fiatCurrencyData = useSelector((state) => state.fiatCurrency.data);
  // useEffect(() => {
  //   localStorage.setItem('reversed', reversed.toString());
  // }, [reversed]);

  // useEffect(() => {
  //   isP2PPage && setReversed(true);
  // }, [isP2PPage]);

  useEffect(() => {
    dispatch(changeFiatCount(1))
    dispatch(changeCryptoCount(1))

    if(!reversedIcon) {
      dispatch(toggleReversed())
    }
    
    dispatch(changeLastType('crypto'))
  }, [monitoring])

  useEffect(() => {
    dispatch(fetchFiatCurrency(selectedCountry));
    dispatch(fetchCryptoCurrency());
  }, [dispatch, selectedCountry]);

  useEffect(() => {
    if (fiatCurrencyData?.data?.length) {
      dispatch(
        fetchCryptoPrice({
          fiat: selectedFiat.code,
          crypto: selectedCrypto?.code || 'BTC',
        }),
      );
      dispatch(
        fetchFiatPrice({
          crypto: selectedCrypto?.code,
          fiat: selectedFiat?.code,
        }),
      );
      isP2PPage &&
        dispatch(
          fetchP2PPrice({
            fiat: selectedFiat?.code,
            crypto: selectedP2PCrypto.code || 'BTC',
          }),
        );
    }
  }, [
    fiatCurrencyData,
    selectedCrypto,
    selectedP2PCrypto,
    isP2PPage,
    selectedFiat,
    dispatch,
  ]);

  useEffect(() => {
    if (!localStorage.getItem('p2pCryptoAmount')) {
      localStorage.setItem('p2pCryptoAmount', '1');
    }
    setP2PCryptoAmount(localStorage.getItem('p2pCryptoAmount'));
  }, []);

  const toggleSections = () => {
    // setReversed(!reversed);
    dispatch(toggleReversed());
    if(reversedIcon) {
      dispatch(changeLastType('fiat'))
      dispatch(changeCryptoCount(1))
    } else {
      dispatch(changeLastType('crypto'))
      dispatch(changeFiatCount(1))  
    }
  };

  const walletOption = [
    { name: 'Crypto' },
    { name: 'Fiat' },
    { name: 'Banks' },
    { name: 'Sting 4' },
    { name: 'Sting 5' },
  ];

  const onHandleChangeInput = (e) => {
    setTimeout(() => {
      setP2PCryptoAmount(e.target.value);
      localStorage.setItem('p2pCryptoAmount', e.target.value);
    }, 1000);
  };

  const selectFiat = (fiat) => {
    setSelectedFiat(fiat);
    localStorage.setItem(
      'selectedCountry',
      JSON.stringify(getInitialCountryByFiat(fiat.code)),
    );
  }

  return (
    <div
      className={cn(styles.changeSectionWrapper, {
        [styles.reversed]: reversedIcon,
      })}
    >
      <div className={styles.section}>
        <div className={styles.mobileChangeIcon}>
          <Dropdown
            options={walletOption}
            toReceive={reversedIcon}
            toSend={!reversedIcon}
          />
          {isMobile && reversedIcon && (
            <ChangeIcon width={24} height={24} onClick={toggleSections} />
          )}
        </div>
        <InputDropdown
          inputValue={p2pFiatAmount}
          options={fiatCurrencyData?.data}
          creditCard={monitoring ? false : creditCard}
          monitoring={monitoring}
          fiat
          selectedFiat={selectedFiat}
          setSelectedFiat={selectFiat}
        />
      </div>
      {!isMobile && (
        <div className={styles.changeIcon}>
          <ChangeIcon width={24} height={24} onClick={toggleSections} />
        </div>
      )}
      <div className={styles.section}>
        <div className={styles.mobileChangeIcon}>
          <Dropdown
            options={walletOption}
            toReceive={!reversedIcon}
            toSend={reversedIcon}
          />
          {isMobile && !reversedIcon && (
            <ChangeIcon width={24} height={24} onClick={toggleSections} />
          )}
        </div>
        <InputDropdown
          onChange={(e) => onHandleChangeInput(e)}
          type="number"
          inputValue={p2pCryptoAmount}
          options={cryptoList?.data}
          creditCard={monitoring ? false : creditCard}
          monitoring={monitoring}
          crypto
          selectedCrypto={isP2PPage ? selectedP2PCrypto : selectedCrypto}
          setSelectedCrypto={
            isP2PPage ? setSelectedP2PCrypto : setSelectedCrypto
          }
        />
      </div>
    </div>
  );
};

export default ChangeSection;
