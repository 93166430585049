import React, { useState } from 'react';
import Dropdown from '../Dropdown/Dropdown';
import Input from '../Input/Input';
import styles from './styles.module.scss';
import cn from 'classnames';

const InputDropdown = ({
  options,
  creditCard,
  monitoring,
  crypto,
  fiat,
  selectedCrypto,
  setSelectedCrypto,
  selectedFiat,
  setSelectedFiat,
  inputValue,
  onChange,
  type
}) => {
  const storedCrypto = selectedCrypto || {
    name: 'Bitcoin',
    icon: 'https://api-changeexperto.goodface.com.ua/storage/currencies/crypto/btc.svg',
  };

  const storedFiat = selectedFiat || {
    name: 'Brazilian real',
    icon: 'https://api-changeexperto.goodface.com.ua/storage/currencies/currency/brl.svg',
    code: 'BRL',
  };

  const [selectedValue, setSelectedValue] = useState(
    storedCrypto
      ? storedCrypto
      : storedFiat
      ? storedFiat
      : options && options.length > 0
      ? options[0]
      : null
  );
  const handleValueChange = (value) => {
    setSelectedValue(value);
    if (crypto) {
      setSelectedCrypto(value);
    }
    if (fiat) {
      setSelectedFiat(value);
    }
  };

  return (
    <div
      className={cn(styles.inputDropdownWrapper, {
        [styles.monitoring]: false,
      })}
    >
      <div className={styles.input}>
        <Input
          type={fiat ? 'fiat' : 'crypto'}
          value={inputValue}
          onChange={onChange}
          withIcon
          fiat={fiat}
          code={
            fiat
            ? selectedFiat?.code
            : crypto
            ? selectedCrypto?.code
            : selectedValue?.code
          }
          icon={
            fiat
              ? selectedFiat?.icon
              : crypto
              ? selectedCrypto?.icon
              : selectedValue?.icon
          }
          monitoring={monitoring}
        />
      </div>
      <Dropdown
        options={options}
        forInput
        onSelect={handleValueChange}
        monitoring={monitoring}
        crypto={crypto}
        fiat={fiat}
        setSelectedCrypto={setSelectedCrypto}
        selectedCrypto={storedCrypto}
        selectedFiat={selectedFiat}
        setSelectedFiat={setSelectedFiat}
      />
    </div>
  );
};

export default InputDropdown;
