import { useLocation } from 'react-router-dom';
import Footer from '../PageComponent/Footer/Footer';
import Header from '../PageComponent/Header/Header';
import styles from './styles.module.scss';
import cn from 'classnames';
import { countryCode } from '../../helpers/helpers';

const MainLayout = ({ children }) => {
  const location = useLocation();
  const path = location.pathname;
  const language = localStorage.getItem('userLanguage') || 'en';

  const includesStrings = [
    `/news`,
    `/p2p/${countryCode}`,
    `/faq`,
    `/partners`,
    `/contacts`,
    'view-news',
  ];

  const isPathIncluded = includesStrings.some((str) => path.includes(str));

  return (
    <div
      className={cn(styles.layout)}
    >
      <Header />
      {children}
      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
