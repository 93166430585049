import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from './styles.module.scss';
import ChangeSection from '../ChangeSection/ChangeSection';
import cn from 'classnames';
import { countryCode } from '../../../helpers/helpers';

const PageMobileHeader = ({title}) => {
  const location = useLocation();
  const language = localStorage.getItem('userLanguage') || 'en';

  let isShowing = location.pathname.includes('news') || location.pathname.includes('contacts') || location.pathname.includes('partners') || location.pathname.includes('faq')

  return (
    <div className={styles.wrapper}>
      <div
        className={cn(styles.header, {
          [styles.notMainPage]:
            location.pathname !== `/${language}/${countryCode}` &&
            location.pathname !== `/${countryCode}`,
        })}
      >
        {title}
      </div>
      <div className={styles.dropdownSection}>
          {!isShowing && <ChangeSection monitoring />}
      </div>
    </div>
  );
};

export default PageMobileHeader;
