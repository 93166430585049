import brazilFlag from '../shared/assets/Flags/brazil.svg';
import argentinaFlag from '../shared/assets/Flags/argentina.svg';
import mexicoFlag from '../shared/assets/Flags//mexico.svg';
import chileFlag from '../shared/assets/Flags/chile.svg';
import peruFlag from '../shared/assets/Flags/peru.svg';

export const currencyInCountry = {
  brazil: 'BRL',
  argentina: 'ARS',
  mexico: 'MXN',
  peru: 'PEN',
  chile: 'CLP',
};

export const countryList = [
  { name: 'Brazil', icon: brazilFlag },
  { name: 'Argentina', icon: argentinaFlag },
  { name: 'Mexico', icon: mexicoFlag },
  { name: 'Peru', icon: peruFlag },
  { name: 'Chile', icon: chileFlag },
];

export const getInitialCountryByFiat = (fiatName) => {
  switch (fiatName) {
    case 'BRL':
      return {
        name: 'Brazil',
        icon: 'https://api-changeexperto.goodface.com.ua/icons/countries/brazil.svg',
      };
    case 'ARS':
      return {
        name: 'Argentina',
        icon: 'https://api-changeexperto.goodface.com.ua/icons/countries/argentina.svg',
      };
    case 'MXN':
      return {
        name: 'Mexico',
        icon: 'https://api-changeexperto.goodface.com.ua/icons/countries/mexico.svg',
      };
    case 'PEN':
      return {
        name: 'Peru',
        icon: 'https://api-changeexperto.goodface.com.ua/icons/countries/peru.svg',
      };
    case 'CLP':
      return {
        name: 'Chile',
        icon: 'https://api-changeexperto.goodface.com.ua/icons/countries/chile.svg',
      };
    default:
      return {
        name: 'Brazil',
        icon: 'https://api-changeexperto.goodface.com.ua/icons/countries/brazil.svg',
      };
  }
};

export const storedCountry = JSON.parse(
  localStorage.getItem('selectedCountry'),
) || {
  name: 'Brazil',
  icon: 'https://api-changeexperto.goodface.com.ua/icons/countries/brazil.svg',
};
export const countryCode = storedCountry?.name.toLowerCase();

export const getInitialFiatByCountry = (countryName) => {
  switch (countryName) {
    case 'brazil':
      return {
        name: 'Brazilian real',
        icon: 'https://api-changeexperto.goodface.com.ua/storage/currencies/currency/brl.svg',
        code: 'BRL',
      };
    case 'argentina':
      return {
        name: 'Argentina peso',
        icon: 'https://api-changeexperto.goodface.com.ua/storage/currencies/currency/ars.svg',
        code: 'ARS',
      };
    case 'mexico':
      return {
        name: 'Mexican peso',
        icon: 'https://api-changeexperto.goodface.com.ua/storage/currencies/currency/mxn.svg',
        code: 'MXN',
      };
    case 'peru':
      return {
        name: 'Peruvian sol',
        icon: 'https://api-changeexperto.goodface.com.ua/storage/currencies/currency/pen.svg',
        code: 'PEN',
      };
    case 'chile':
      return {
        name: 'Chilean pesos',
        icon: 'https://api-changeexperto.goodface.com.ua/storage/currencies/currency/clp.svg',
        code: 'CLP',
      };
    default:
      return {
        name: 'Brazilian real',
        icon: 'https://api-changeexperto.goodface.com.ua/storage/currencies/currency/brl.svg',
        code: 'BRL',
      };
  }
};

export const languageOptions = [
  { name: 'EN', code: 'en' },
  { name: 'ES', code: 'es' },
  { name: 'PT', code: 'pt' },
];

export const languageData = {
  "en": { name: 'EN', code: 'en' },
  "es": { name: 'ES', code: 'es' },
  "pt": { name: 'PT', code: 'pt' },
};

export const countriesData = {
  'brazil': {
    name: 'Brazil',
    icon: 'https://api-changeexperto.goodface.com.ua/icons/countries/brazil.svg',
  },
  'peru': {
    name: "Peru",
    icon: "https://api-changeexperto.goodface.com.ua/icons/countries/peru.svg"
  },
  'mexico': {
    name: 'Mexico',
    icon: 'https://api-changeexperto.goodface.com.ua/icons/countries/mexico.svg',
  },
  'argentina': {
    name: 'Argentina',
    icon: 'https://api-changeexperto.goodface.com.ua/icons/countries/argentina.svg',
  },
  'chile': {
    name: 'Chile',
    icon: 'https://api-changeexperto.goodface.com.ua/icons/countries/chile.svg',
  }
}

export const langList = ['en', 'es', 'pt']
export const countries = ['brazil', 'peru', 'mexico', 'argentina', 'chile']