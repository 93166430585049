import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getP2PPriceEndpoint } from '../api/endpoints';

export const fetchP2PPrice = createAsyncThunk(
  'p2pPrice/fetchP2PPrice',
  async ({ fiat, crypto }) => {
    try {
      const response = await getP2PPriceEndpoint(fiat, crypto);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

const p2pPriceSlice = createSlice({
  name: 'p2pPrice',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchP2PPrice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchP2PPrice.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchP2PPrice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default p2pPriceSlice.reducer;
