import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import Input from '../Input/Input';
import { ReactComponent as ArrowDown } from '../../assets/DropdownIcon.svg';
import styles from './styles.module.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { changeLanguage } from '../../../i18n';
import { countries, countriesData, getInitialFiatByCountry, langList, languageData } from '../../../helpers/helpers';
import { fetchExpressPricePageTranslations } from '../../../store/getTranslationsSlice';
import { pathPages } from '../../../api/constants';
import { changeFiatCount } from '../../../store/changeFiatCountSlice';
import { changeCryptoCount } from '../../../store/changeCryptoCountSlice';
import {changeLastType} from '../../../store/changeLastType';

const Dropdown = ({
  options,
  countryDropdown,
  languageDropdown,
  toSend,
  toReceive,
  forInput,
  onSelect,
  crypto,
  fiat,
  oneValue,
  setSelectedCrypto,
  setSelectedFiat,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isP2PPage = location.pathname.includes('p2p');
  const newsId = JSON.parse(localStorage?.getItem('newsId'));

  const language = location.pathname.split('/')[1] == '' || !langList.includes(location.pathname.split('/')[1]) || countries.includes(location.pathname.split('/')[1])  ? 'en' : location.pathname.split('/')[1]
  const country = countries.includes(location.pathname.split('/')[1]) ? location.pathname.split('/')[1] : (countries.includes(location.pathname.split('/')[2]) ? location.pathname.split('/')[2] : 'brazil')


  const translations = useSelector(
    (state) =>
      state.translations.expressPricePage.data?.data[language.toUpperCase()],
  );

  const storedCountry = countryDropdown
    ? countriesData[country] || {
        name: 'Brazil',
        icon: 'https://api-changeexperto.goodface.com.ua/icons/countries/brazil.svg',
    }
    : null;
  const storedCrypto = crypto
    ? (isP2PPage && JSON.parse(localStorage.getItem('selectedP2PCrypto'))) ||
      JSON.parse(localStorage.getItem('selectedCrypto')) || {
        name: 'Bitcoin',
        icon: 'https://api-changeexperto.goodface.com.ua/storage/currencies/crypto/btc.svg',
      }
    : null;

  const storedFiat = fiat
    ? JSON.parse(localStorage.getItem('selectedFiat')) || {
        name: 'Brazilian real',
        icon: 'https://api-changeexperto.goodface.com.ua/storage/currencies/currency/brl.svg',
        code: 'BRL',
      }
    : null;

  const storedLanguage = languageData[language]


  // eslint-disable-next-line no-unused-vars
  const [languageSelected, setLanguageSelected] = useState(storedLanguage);
  const [inputValue, setInputValue] = useState(options);
  const initialSelectedValue =
    storedCountry ||
    storedCrypto ||
    storedFiat ||
    storedLanguage ||
    (options && options[0]) ||
    null;
  const [selectedValue, setSelectedValue] = useState(
    fiat
      ? getInitialFiatByCountry(country)
      : toSend || toReceive
      ? ''
      : initialSelectedValue,
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const [inputPrice, setInputPrice] = useState(false) 
  const inputRef = useRef(null);

  useEffect(() => {
    !translations && dispatch(fetchExpressPricePageTranslations());
  }, [dispatch, translations]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setInputPrice(false);
      }
    };

    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  })

  function handleInput(e) {
    if(fiat) {
      dispatch(changeFiatCount(e.target.value != '' ? parseInt(e.target.value) : 1))
      dispatch(changeLastType('fiat'))
    } else {
      dispatch(changeCryptoCount(e.target.value != '' ? parseInt(e.target.value) : 1))
      dispatch(changeLastType('crypto'))
    }
  }

  const toggleDropdown = () => {
    if (!oneValue) {
      setDropdownOpen(!dropdownOpen);
    }
  };

  const onOpenDropdown = () => {
    if (((crypto || fiat) && !dropdownOpen) || (!crypto && !fiat)) {
      !oneValue && setDropdownOpen(true);
    }
  };

  const onCloseDropdown = () => {
    !oneValue && setDropdownOpen(false);
  };

  const onChangeInputCrypto = (e) => {
    const selectedOptions = options.filter((el) =>
      el.name.toLowerCase().includes(e.target.value.toLowerCase()),
    );
    setInputValue(selectedOptions);
  };

  const handleChangeValue = (name) => {
    if (onSelect) {
      onSelect(name);
    }
    setSelectedValue({ name: name.name, code: name?.code, icon: name.icon });
    setDropdownOpen(false);
    if (crypto) {
      isP2PPage
        ? localStorage.setItem('selectedP2PCrypto', JSON.stringify(name))
        : localStorage.setItem('selectedCrypto', JSON.stringify(name));
      setSelectedCrypto({ name: name.name, code: name?.code, icon: name.icon });
      setInputValue(options);
    }
    if (fiat) {
      localStorage.setItem('selectedFiat', JSON.stringify(name));
      setSelectedFiat({ name: name.name, code: name?.code, icon: name.icon });
      setInputValue(options);
    }

    if (countryDropdown) {
      const countryCode = name?.name.toLowerCase();


      let newWay = language == 'en' || countries.includes(language) ? `/${countryCode}/` : `/${language}/${countryCode}/`

      if(location.pathname.includes('p2p')) newWay += 'p2p'
      if(location.pathname.includes('partners')) newWay += 'partners'
      if(location.pathname.includes('contacts')) newWay += 'contacts'
      if(location.pathname.includes('faq')) newWay += 'faq'
      if(location.pathname.includes('news')) newWay += 'news'

      navigate(newWay)

      localStorage.setItem('selectedFiat', JSON.stringify(getInitialFiatByCountry(countryCode)));
      // setSelectedFiat({ name: name.name, code: name?.code, icon: name.icon });
      setInputValue(options);

      window.location.reload();
    }
  };

  function handleChangeLanguage(name, lang) {

    if (language !== lang) {
      let newPath = lang == 'en' ? (country == 'brazil' ? '/' : `/${country}/`) : (country == 'brazil' ? `/${lang}/` : `/${lang}/${country}/`)

      if(location.pathname.includes('p2p')) newPath += 'p2p'
      if(location.pathname.includes('partners')) newPath += 'partners'
      if(location.pathname.includes('contacts')) newPath += 'contacts'
      if(location.pathname.includes('faq')) newPath += 'faq'
      if(location.pathname.includes('news')) newPath += 'news'

      console.log(newPath)

      setLanguageSelected({ name: name.name, code: lang });
      navigate(newPath);
    }

    changeLanguage(lang, () => {
      window.location.reload();
    });
  }

  return (
    <div
      className={cn(styles.dropdown, {
        [styles.isOpen]: dropdownOpen,
        [styles.countryDropdown]: countryDropdown,
        [styles.toSend]: toSend,
        [styles.toReceive]: toReceive,
        [styles.forInput]: forInput,
      })}
      ref={dropdownRef}
    >
      <div
        className={cn(styles.selectedValue, {
          [styles.countryDropdown]: countryDropdown,
          [styles.languageDropdown]: languageDropdown,
          [styles.toSend]: toSend,
          [styles.toReceive]: toReceive,
          [styles.isOpen]: dropdownOpen,
          [styles.forInput]: forInput,
          [styles.oneValue]: oneValue,
        })}
        onClick={fiat || crypto ? onOpenDropdown : toggleDropdown}
      >
        {toSend && translations?.to_send}
        {toReceive && translations?.to_receive}
        {selectedValue && selectedValue.icon && !forInput && (
          <img
            src={selectedValue.icon}
            alt={`${selectedValue.name} icon`}
            width={15}
            height={15}
          />
        )}

        {/* {dropdownOpen && (crypto || fiat) ? (
          <div className={styles.dropdownInput}>
            <Input onChange={onChangeInputCrypto} autoFocus dropdownInput />
          </div>
        ) : crypto ? (
          selectedCrypto.name
        ) : selectedValue ? (
          selectedValue.name
        ) : (
          ''
        )} */}

        <span>{selectedValue.name}</span>
        

        <ArrowDown
          onClick={onCloseDropdown}
          className={dropdownOpen ? styles.arrowDownRotated : styles.arrowDown}
          height={forInput ? 8 : 5}
          width={forInput ? 11 : 8}
        />
      </div>
      {dropdownOpen && !inputPrice && (
        <ul
          className={cn(styles.itemList, {
            [styles.toSend]: toSend,
            [styles.toReceive]: toReceive,
            [styles.forInput]: forInput,
          })}
        >
          {(inputValue || options)?.map((option, index) => (
            <li
              className={cn(styles.items, {
                [styles.forInput]: forInput,
              })}
              key={index}
              onClick={() =>
                languageDropdown
                  ? handleChangeLanguage({ name: option.name }, option?.code)
                  : handleChangeValue({
                      name: option.name,
                      code: option?.code,
                      icon: option.icon,
                    })
              }
            >
              {forInput && option.icon && (
                <div className={styles.iconLabel}>
                  <img
                    src={option.icon}
                    alt={`${option.name} icon`}
                    width={40}
                    height={40}
                  />
                </div>
              )}
              {option.icon && !forInput && (
                <img
                  src={option.icon}
                  alt={`${option.name} icon`}
                  width={15}
                  height={15}
                />
              )}
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.string,
    }),
  ),
  countryDropdown: PropTypes.bool,
  toSend: PropTypes.bool,
  toReceive: PropTypes.bool,
  forInput: PropTypes.bool,
  crypto: PropTypes.bool,
  fiat: PropTypes.bool,
  oneValue: PropTypes.bool,
};

export default Dropdown;
