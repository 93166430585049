import React from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';

import rightIcon1 from '../../assets/Header/HeaderLef1.png';
import leftIcon1 from '../../assets/Header/HederRight1.png';
import faqRightIcon from '../../assets/Header/faqRight.png';
import faqLeftIcon from '../../assets/Header/faqLeft.png';
import contactsRightIcon from '../../assets/Header/contactsRight.png';
import contactsLeftIcon from '../../assets/Header/contactsLeft.png';
import partnersRight from '../../assets/Header/partnersRight.png';
import partnersLeft from '../../assets/Header/partnersLeft.png';
import newsRight from '../../assets/Header/newsRight.png';
import newsLeft from '../../assets/Header/newsLeft.png';
import { useLocation } from 'react-router-dom';
import { countryCode, langList } from '../../../helpers/helpers';

const HeaderIcons = React.memo(() => {
  const location = useLocation();
  const language = langList.includes(location.pathname.split('/')[1]) ? location.pathname.split('/')[1] : 'en';

  const getIconsByPathname = (pathname) => {
    console.log(pathname)
    switch (pathname) {
      case language === 'en' ? `/${countryCode}` : `/${language}/${countryCode}`:
        return { rightIcon: rightIcon1, leftIcon: leftIcon1 };
      case 'p2p':
        return { rightIcon: rightIcon1, leftIcon: leftIcon1 };
      case language === 'en' ? `/faq` : `/${language}/faq`:
        return { rightIcon: faqRightIcon, leftIcon: faqLeftIcon };
      case language === 'en' ? `/contacts` : `/${language}/contacts`:
        return { rightIcon: contactsLeftIcon, leftIcon: contactsRightIcon };
      case language === 'en' ? `/partners` : `/${language}/partners`:
        return { rightIcon: partnersLeft, leftIcon: partnersRight };
      case language === 'en' ? `/news` : `/${language}/news`:
        return { rightIcon: newsLeft, leftIcon: newsRight };
      default:
        return { rightIcon: null, leftIcon: null };
    }
  };
  const { rightIcon, leftIcon } = getIconsByPathname(location.pathname);

  return (
    <div className={styles.headerIconsWrapper}>
      <div
        className={cn(styles.rightIcon, {
          [styles.default]:
            location.pathname === '/p2p' || language === 'en'
              ? location.pathname === `/${countryCode}`
              : location.pathname === `/${language}/${countryCode}`,

          [styles.faq]: location.pathname.includes('faq'),
          [styles.contacts]: location.pathname.includes('contacts'),
          [styles.partners]: location.pathname.includes('partners'),
          [styles.news]: location.pathname.includes('news'),
        })}
      >
        {rightIcon ? (
          <img src={rightIcon} alt="Right Icon" />
        ) : (
          <div className={styles.placeholder} style={{ opacity: 0 }} />
        )}
      </div>
      <div
        className={cn(styles.leftIcon, {
          [styles.default]:
            location.pathname === '/p2p' || language === 'en'
              ? location.pathname === `/${countryCode}`
              : location.pathname === `/${language}/${countryCode}`,

          [styles.faq]: location.pathname.includes('faq'),
          [styles.contacts]: location.pathname.includes('contacts'),
          [styles.partners]: location.pathname.includes('partners'),
          [styles.news]: location.pathname.includes('news'),
        })}
      >
        {leftIcon ? (
          <img src={leftIcon} alt="Left Icon" />
        ) : (
          <div className={styles.placeholder} style={{ opacity: 0 }} />
        )}
      </div>
    </div>
  );
});

export default HeaderIcons;
